import React, { useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useSpring, animated, config } from "react-spring/three";
import "./styles.css";
import * as THREE from "three";
import { ShaderMaterial } from "three";

class Material extends ShaderMaterial {
  constructor() {
    super({
      uniforms: {},
      vertexShader: `
varying vec2 vUv;
attribute vec2 my;
varying vec2 bbq;

void main() {
  vUv = uv;
  bbq = my;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`,
      fragmentShader: `
varying vec2 vUv;
uniform sampler2D map;

void main() {
  gl_FragColor = vec4(vUv, 1, 1);
}
`
    });
  }
}

/**
 *
 * @param width - number rect width
 * @param height - number rect height
 */
export const createTooltipGeometry = ({
  width,
  height,
  radius: r,
  triangleWidth,
  triangleHeight
}) => {
  const radius = Math.min(r, height / 2);
  const shape = new THREE.Shape();
  // 直接从尖尖开始画，把尖尖放在原点。
  shape.lineTo(triangleWidth / 2, triangleHeight);
  shape.lineTo(width / 2 - radius, triangleHeight);
  shape.quadraticCurveTo(
    width / 2,
    triangleHeight,
    width / 2,
    triangleHeight + radius
  );
  shape.lineTo(width / 2, height - radius + triangleHeight);
  shape.quadraticCurveTo(
    width / 2,
    height + triangleHeight,
    width / 2 - radius,
    height + triangleHeight
  );

  shape.lineTo(-(width / 2 - radius), height + triangleHeight);
  shape.quadraticCurveTo(
    -width / 2,
    height + triangleHeight,
    -width / 2,
    height + triangleHeight - radius
  );

  shape.lineTo(-width / 2, height + triangleHeight - radius);
  // add
  shape.lineTo(-width / 2, triangleHeight + radius);
  // after add
  shape.quadraticCurveTo(
    -width / 2,
    triangleHeight,
    -(width / 2 - radius),
    triangleHeight
  );
  shape.lineTo(-triangleWidth / 2, triangleHeight);

  const geo = new THREE.ShapeBufferGeometry(shape).rotateZ(Math.PI);
  const { uv } = geo.attributes;

  geo.setAttribute(
    "my",
    new THREE.BufferAttribute(new Float32Array(uv.array.length), 2)
  );
  console.log(geo.attributes);

  const uvs = new Float32Array(
    Array.from(uv.array).map((v, i) => {
      // i % 2 === 0 => x
      // i % 2 === 1 => y
      const r = i % 2;
      switch (r) {
        case 0:
          return (v + width / 2) / width;
        case 1:
          return v / (height + triangleHeight);
        default:
          return v;
      }
    })
  );
  geo.setAttribute("uv", new THREE.BufferAttribute(uvs, 2));
  return geo;
};

function MyRotatingBox() {
  const geo = createTooltipGeometry({
    width: 50,
    height: 25,
    radius: 40,
    triangleHeight: 10,
    triangleWidth: 10
  });

  const mat = new Material();
  window.mat = mat;

  return (
    <mesh
      geometry={geo}
      material={mat}
      scale={[0.1, 0.1, 0]}
      position={[0, 1, 0]}
    ></mesh>
  );
}

export default function App() {
  return (
    <div className="App">
      <Canvas>
        <MyRotatingBox />
        <ambientLight intensity={0.1} />
        <directionalLight />
      </Canvas>
    </div>
  );
}
